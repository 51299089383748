import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Accordion from 'react-bootstrap/Accordion'

const CoverageRow = ({ coveredUnder }) => {
     if (coveredUnder == 'Full Coverage') {
          return (<div className="full"></div>);
     } else if (coveredUnder == 'Partial Coverage') {
          return <div className="half-gradient"></div>;
     }
     else if (coveredUnder == 'No Coverage') {
          return <div className="empty"></div>;
     }

     return <></>;
}

const ContentfulConditionCoverage = (coverage) => {
     if (!coverage.coveredUnderMajorMedical) {
          var wholePetWithWellnessRate = parseInt(coverage.coverageAmountWholePetWithWellness) / parseInt(coverage.veterinaryBillAmount);
          var majorMedicalRate = parseInt(coverage.coverageAmountMajorMedical) / parseInt(coverage.veterinaryBillAmount);
          var petWellnessRate = parseInt(coverage.coverageAmountPetWellness) / parseInt(coverage.veterinaryBillAmount);
          var border = 0;

          if (wholePetWithWellnessRate) {
               border = 1;
          }

          var wholePetWithWellnessData = {
               datasets: [{
                    data: [wholePetWithWellnessRate, 1 - wholePetWithWellnessRate],
                    backgroundColor: [
                         '#fdb813',
                         '#dae8ed'
                    ],
                    borderWidth: border,
               }]
          }

          var majorMedicalData = {
               datasets: [{
                    data: [majorMedicalRate, 1 - majorMedicalRate],
                    backgroundColor: [
                         '#0d63ac',
                         '#dae8ed'
                    ],
                    borderWidth: border,
               }]
          }

          var petWellnessData = {
               datasets: [{
                    data: [petWellnessRate, 1 - petWellnessRate],
                    backgroundColor: [
                         '#47ab4b',
                         '#dae8ed'
                    ],
                    borderWidth: border,
               }]
          }

          var options = {
               tooltips: {
                    enabled: false
               }
          };
     }
     const coverageID = typeof coverage.sys != 'undefined' ? coverage.sys.id : coverage.id;

     const formatNumber = (num) => {
          return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     }

     return (
          <>
               {coverage.coveredUnderMajorMedical ? (
                    // Simple table
                    coverage.template == 'desktop' ? (
                         <>
                              <tr className="coverage-row">
                                   <td className="coverage-condition">{coverage.conditionName && coverage.conditionName}</td>
                                   <td className="coverage-price one">
                                        {coverage.coveredUnderWholePetWithWellness && <CoverageRow coveredUnder={coverage.coveredUnderWholePetWithWellness} />}
                                   </td>
                                   <td className="coverage-price two">
                                        {coverage.coveredUnderMajorMedical && <CoverageRow coveredUnder={coverage.coveredUnderMajorMedical} />}
                                   </td>
                                   <td className="coverage-price three">
                                        {coverage.coveredUnderPetWellness && <CoverageRow coveredUnder={coverage.coveredUnderPetWellness} />}
                                   </td>
                              </tr>
                         </>
                    ) : (
                              <>
                                   <Accordion.Toggle as="div" eventKey={coverageID} onClick={coverage.onClick}>
                                        <h3 className="covered-accordion-title">{coverage.conditionName}</h3>
                                   </Accordion.Toggle>
                                   <Accordion.Collapse eventKey={coverageID}>
                                        <div className="covered-accordion-content">
                                             <div className="whole-pet">
                                                  <img loading="lazy" src="/images/wholepet.png" alt='wholepet' />
                                                  {coverage.coveredUnderWholePetWithWellness && <CoverageRow coveredUnder={coverage.coveredUnderWholePetWithWellness} />}
                                             </div>
                                             <div className="major-medical">
                                                  <img loading="lazy" src="/images/major-medical.png" alt='major-medical' />
                                                  {coverage.coveredUnderMajorMedical && <CoverageRow coveredUnder={coverage.coveredUnderMajorMedical} />}
                                             </div>
                                             <div className="wellness-plus">
                                                  <img loading="lazy" src="/images/petwellness.png" alt='wellness-plus' />
                                                  {coverage.coveredUnderPetWellness && <CoverageRow coveredUnder={coverage.coveredUnderPetWellness} />}
                                             </div>
                                        </div>
                                   </Accordion.Collapse>
                              </>
                         )
               ) : (
                         //Coverage Detail View with Charts
                         <>
                              <p>{coverage.conditionDescription && coverage.conditionDescription}</p>
                              <h2>Veterinary bill ${formatNumber(coverage.veterinaryBillAmount && coverage.veterinaryBillAmount)}</h2>
                              <div className="row">
                                   <div className="col-lg-4">
                                        <div className="reimbursement">
                                             <div className="wp-coverageAmount">
                                                  <span>$</span>
                                                  {formatNumber(coverage.coverageAmountWholePetWithWellness && coverage.coverageAmountWholePetWithWellness)}
                                             </div>
                                             <p>Reimbursement</p>
                                        </div>
                                        <Doughnut data={wholePetWithWellnessData} width={250} height={250} options={options} />
                                        <div className="logo">
                                             <img loading="lazy" src="/images/wholepet.png" alt='wholepet' />
                                        </div>
                                   </div>
                                   <div className="col-lg-4">
                                        <div className="reimbursement">
                                             <div className="mm-coverageAmount">
                                                  <span>$</span>
                                                  {formatNumber(coverage.coverageAmountMajorMedical && coverage.coverageAmountMajorMedical)}
                                             </div>
                                             <p>Reimbursement</p>
                                        </div>
                                        <Doughnut data={majorMedicalData} width={240} height={250} options={options} />
                                        <div className="logo">
                                             <img loading="lazy" src="/images/major-medical.png" alt='major-medical' />
                                        </div>
                                   </div>
                                   <div className="col-lg-4">
                                        <div className="reimbursement">
                                             <div className="pw-coverageAmount">
                                                  <span>$</span>
                                                  {formatNumber(coverage.coverageAmountPetWellness && coverage.coverageAmountPetWellness)}
                                             </div>
                                             <p>Reimbursement</p>
                                        </div>
                                        <Doughnut data={petWellnessData} width={240} height={250} options={options} />
                                        <div className="logo">
                                             <img loading="lazy" src="/images/petwellness.png" alt='petwellness' />
                                        </div>
                                   </div>
                              </div>
                         </>
                    )}
          </>
     );
};

export default ContentfulConditionCoverage;
